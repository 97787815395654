import React from "react"

import "./Description.css"

// interface DescriptionProps {
//   heading: string
//   text: string
// }

const Description = ({ heading, text, smallHeading }) => {
  return (
    <div className="DescriptionContainer">
      <div className="Description">
        {smallHeading ? <h3>{heading}</h3> : <h2>{heading}</h2>}
        <p>{text}</p>
      </div>
    </div>
  )
}

Description.defaultProps = {
  smallHeading: false,
}

export default Description
