import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "./ContactIcons.css"

const ContactIcons = () => {
  const query = useStaticQuery(graphql`
    query {
      allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "contact"}}) {
        edges {
          node {
            childMarkdownRemark {
              frontmatter {
                emailContactIcon {
                  heading
                  email
                  icon {
                    publicURL
                  }
                }
                phoneContactIcon {
                  heading
                  phoneNumber
                  icon {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const data = query.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const Icon = ({ header, text, Image }) => (
    <div className="IconC">
      <div className="Image">
        <img src={Image} alt="Decorative image." />
      </div>
      <h3>{header}</h3>
      <p>{text}</p>
    </div>
  )

  return (
    <div className="ContactIconsContainer">
      <div className="ContactIcons">
        <Icon header={data.emailContactIcon.heading} text={data.emailContactIcon.email} Image={data.emailContactIcon.icon.publicURL} />
        <Icon header={data.phoneContactIcon.heading} text={data.phoneContactIcon.phoneNumber} Image={data.phoneContactIcon.icon.publicURL} />
      </div>
    </div>
  )
}

export default ContactIcons
