import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'

import "./ContactForm.css"

const ContactForm = () => {
  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  return (
    <div className="ContactFormContainer">
      <Formik
        initialValues={{
          name: '',
          email: '',
          organisation: '',
          message: '',
        }}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "Just-A-Sec-Contact-Form", ...values })
          })
          .then(() => {
            alert('Thank you, your message has been sent. We will get back to you soon.');
            actions.resetForm()
          })
          .catch(() => {
            alert('Unfortunately, your message could not be sent. Please try again or contact us another way.');
          })
          .finally(() => actions.setSubmitting(false))
        }}
        validate={values => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          const errors = {};
          if(!values.name) {
            errors.name = 'Enter a name'
          }
          if(!values.email || !emailRegex.test(values.email)) {
            errors.email = 'Enter a valid email address'
          }
          if(!values.organisation) {
            errors.organisation = 'Enter an organisation'
          }
          if(!values.message) {
            errors.message = 'Enter a message'
          }
          return errors;
        }}
      >
      {() => (
        <Form name="Just-A-Sec-Contact-Form" data-netlify={true} className="Form">
          {/* <label htmlFor="name">Name: </label> */}
          <Field name="name" placeholder="Name" />
          <div className="Error">
            <ErrorMessage name="name" />
          </div>
    
          {/* <label htmlFor="email">Email: </label> */}
          <Field name="email" placeholder="Email Address" />
          <div className="Error">
            <ErrorMessage name="email" />
          </div>
    
          {/* <label htmlFor="organisation">Organisation: </label> */}
          <Field name="organisation" placeholder="Organisation" />
          <div className="Error">
            <ErrorMessage name="organisation" />
          </div>
    
          {/* <label htmlFor="message">Message: </label> */}
          <Field name="message" placeholder="Your Message..." component="textarea" />
          <div className="Error">
            <ErrorMessage name="message" />
          </div>
    
          <button type="submit" className="Submit">Send Message</button>
        </Form>
      )}
      </Formik>
    </div>
  )
}

export default ContactForm
