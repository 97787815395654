import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeroBox from "../components/HeroBox"
import LayoutInner from "../components/LayoutInner"
import Description from "../components/Description"
import ContactForm from "../components/ContactForm"
import ContactIcons from "../components/ContactIcons"
import CarouselContact from "../components/CarouselContact"

export const query = graphql`
  query {
    allFile (filter: {sourceInstanceName: {eq: "content"} name: {eq: "contact"}}) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              headerTitle
              headerSubtitle
              paragraphHeading
              paragraphText
            }
          }
        }
      }
    }
  }
`

const ContactPage = ( props ) => {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <>
      <Layout>
        <Seo title="Contact Us" />
        <HeroBox heading={data.headerTitle} text={data.headerSubtitle} />
        <LayoutInner>
        <Description heading={data.paragraphHeading} text={data.paragraphText} smallHeading={false} />
          <ContactForm />
          <ContactIcons />
          <CarouselContact />
        </LayoutInner>
      </Layout>
    </>
  )
}

export default ContactPage
